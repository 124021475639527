import { useEffect, useState, useContext } from "react";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/ui/Button";

import SubNav from "../menu/SubNav";
import Footer from "../menu/Footer";
import Hooks from "components/tool/Hooks";
import SynapsClient from "lib/synaps";
import { faPassport } from "@fortawesome/pro-duotone-svg-icons";
import { AuthContext } from "context/auth";
import { useLocation } from "react-router-dom";
import SessionService from "services/session";
import DuplicateOptions from "components/user/DuplicateData";
import Cookie from "js-cookie";
import AuthService from "services/auth";
import { useHistory } from "react-router-dom";
import Input from "components/ui/Input";
import Wallet from "../wallet/index";

export default function IcecreamPage() {
  const width = Hooks();
  const [authState, authDispatch] = useContext(AuthContext);
  const [project] = useState(
    window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false
  );
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [isInit, setIsInit] = useState(false);
  const [ready, setReady] = useState(false);

  const [iceCreamStatus, setIceCreamStatus] = useState(""); 
  const [session, setSession] = useState(""); 
  const [sessionStatus, setSessionStatus] = useState("PENDING");
  const router = useHistory();

  function InitSynaps(session) {
    setSession(session)
    setReady(true)
    setIsInit(true)
  }

  useEffect(async () => {
    setReady(false)
    await AuthService.Me(project.toString())
      .then(async (data) => {
        const auth = {
          user: {
            email: data.email,
            code: "",
            type: data.account_type,
            project: data.project,
            session_id: data.session_id,
            access: data.access_token,
          },
          settings: data.settings,
          authenticated: true,
        };

        await setIceCreamStatus(data.icecream_status);
        await setSessionStatus(data.session_status);

        authDispatch({
          type: "SET_AUTH",
          payload: auth,
        });

        Cookie.set("s_auth", data.access_token);
        await InitSynaps(
          data.session_id,
        );
        setReady(true)
      })
      .catch((err) => {
        console.log(err)
          InitSynaps(
            authState.user.session_id,
          );
        setOpen(false);
      });
    return () => {};
  }, [authState.user.session_id]);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        {ready ? <>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container">            
              {iceCreamStatus === 'PAID' && isInit ?
             <div className="md:flex mx-auto md:justify-between">
              <div
                className={`mt-5 text-center mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-1/2`}
              >
                <FontAwesomeIcon
                  className={
                    "text-gray-500 text-3xl mx-auto mt-3 text-3xl mt-0.5"
                  }
                  icon={faPassport}
                ></FontAwesomeIcon>
                <p className="mt-1">
                  Click on the following button to start verification process
                </p>
                <a href={`https://verify.synaps.io/?with_finish_button=true&callback_url=https://icecreamswap.synaps.me&session_id=`+session + `&service=`+authState.user.type.toLowerCase()} target="_blank"
                  type="submit"
                  className="mx-auto my-2 mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus-primary-btn active:bg-primary-700 transition duration-150 ease-in-out"
                >
                  Verify my identity
                </a>
              </div>

              <div className="mt-5 md:w-1/2 mx-auto">
                  <div className="bg-white rounded-xl p-6 shadow verification-steps text-network">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">
                      Start your verification process
                    </h3>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>Complete each verification step</div>
                    </div>

                    <div className="flex mt-5">
                      <div className="mt-3">
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        <p>Your document will be verified by our agents.</p>
                        <p>
                          (It can take up to 24 hours during peak traffic
                          times.)
                        </p>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        You will receive an e-mail notification if any
                        document(s) are{" "}
                        <span className="text-red-500">Rejected</span>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Once every step has been{" "}
                        <span className="text-green-500">Verified</span>, you
                        receive a validation e-mail.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            
              {(authState.settings !== undefined &&
                authState.settings !== null && iceCreamStatus === "") ? (
                <div className="mt-5 w-1/2 mx-auto">
                  <div className="bg-white rounded-xl p-6 shadow verification-steps text-network">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">
                      Start your verification process
                    </h3>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Connect your <strong>wallet</strong> you used on the <strong>IcecreamSwap</strong> plateform and sign a message
                      </div>
                    </div>

                    <div className="flex mt-5 justify-center">
                        <div></div>
                        <div className="cursor-pointer hover:border-primary-700 focus:ring-primary-500 hover:bg-primary-600 flex transition ease-in duration-150 justify-center border  bg-primary-500 rounded-xl shadow-sm text-sm font-medium focus:outline-none focus:ring-2">
                          <Wallet
                            target="_blank"
                            rel="noreferrer"
                            className="block px-4 py-3 text-white font-bold "
                          >
                          </Wallet>
                        </div>
                        <div></div>
                      </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        We will verify if the payment has been{" "}
                        <span className="text-green-500">done</span>
                      </div>
                    </div>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        In case the payment has been{" "}
                        <span className="text-green-500">done</span>, you will be able to pass the KYC process
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            
          </div>
          <Footer></Footer>
        </div>
        </> : null}
      </div>
    </div>
  );
}
