import {
	faUserTag,
	faClock,
	faBan,
	faCheck,
	faRedo,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import Moment from 'react-moment'
import Alert from '../ui/Alert'
import CardTitle from '../ui/CardTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../ui/Loader'

export default function DuplicateData(props) {
	const [error] = useState(null)

	return (
		<Transition
			show={props.open}
			enter="ease-out duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
					<div
						className="fixed inset-0 transition-opacity"
						aria-hidden="true"
						onClick={() => props.onClose()}>
						<div className="absolute inset-0 bg-gray-800 opacity-75"></div>
					</div>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>

					<div
						className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline">
						<div className="p-4">
							<div>
								<div>
									<div>
										<CardTitle
											value="Retrieve my data"
											icon={faUserTag}></CardTitle>
									</div>
								</div>
								<div className="p-6">
									<span >
										You have already uploaded your data to a previous project, your data is available to be imported for the {props.project.toUpperCase()} KYC. Select the data you wish to import :
									</span>
								</div>
								<div>
									{props.duplicates !== null ?
										<>
											{props.duplicates.map((data, index) => (
												<div
													className="w-full p-4 self-auto"
													key={index}>
													{data.restricted === false && data.expired === false && data.status !== 1 && props.selected === "" ?
														<div onClick={() => props.onSelect(data)} className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
															<div className="p-4 flex justify-between">
																<div>
																	<p className="text-regular font-semibold">{data.project_name.toUpperCase()}</p>
																	<p className="text-sm mt-1">
																		<Moment unix fromNow>
																			{data.creation_date / 1000}
																		</Moment></p>
																</div>
																<div>
																	<div className="mt-3">
																		{data.expired === true && data.restricted === false ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faRedo} /> Expired</p> : null}
																		{data.status === 2 && data.expired === false && data.restricted === false ? <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified</p> : null}
																	</div>
																</div>
															</div>
														</div>
														: null}
													{data.restricted === false && data.expired === false && data.status !== 1 && props.selected === data.session_id ?
														<div className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-not-allowed transition duration-200 ease-in">
															<div className="p-4 flex justify-between">
																<div>
																	<p className="text-regular font-semibold">{data.project_name.toUpperCase()}</p>
																	<p className="text-sm mt-1">
																		<Moment unix fromNow>
																			{data.creation_date / 1000}
																		</Moment></p>
																</div>
																<div className="mt-3">
																	<Loader className="mr-2"></Loader>
																</div>
																<div>
																	<div className="mt-3">
																		{data.expired === true && data.restricted === false ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faRedo} /> Expired</p> : null}
																		{data.status === 2 && data.expired === false && data.restricted === false ? <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified</p> : null}
																	</div>
																</div>
															</div>
														</div>
														: null}
													{data.expired === true || data.restricted === true || data.status === 1 || (props.selected !== "" && props.selected !== data.session_id) ?
														<div className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-not-allowed transition-25 opacity-75 transition duration-200 ease-in">
															<div className="p-4 flex justify-between">
																<div>
																	<p className="text-regular font-semibold">{data.project_name.toUpperCase()}</p>
																	<p className="text-sm mt-1">
																		<Moment unix fromNow>
																			{data.creation_date / 1000}
																		</Moment></p>
																</div>
																<div>
																	<div className="mt-3">
																		{data.expired === true && data.restricted === false ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faRedo} /> Expired</p> : null}
																		{data.status === 2 && data.expired === false && data.restricted === false ? <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified</p> : null}
																		{data.restricted === true ? <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faBan} /> Restricted</p> : null}
																		{data.status === 1 && data.expired === false && data.restricted === false ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faClock} /> Not Verified</p> : null}
																	</div>
																</div>
															</div>
														</div>
														: null}
												</div>
											))}
										</>
										: null}
								</div>
								<div>
									<div className="py-2 px-3">
										{error ? (
											<Alert type="error" title={error}></Alert>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
