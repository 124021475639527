import { useReducer, createContext } from "react";
import InitSignup from "components/auth/InitSignup";

export const AuthContext = createContext();

const initialState = {
  user: {
    email: "",
    code: "",
    account_type: "",
    session_id: "",
    project: "",
    access: "",
    refresh: "",
    first_auth: "NOT_ACTIVATED",
  },
  settings: {},
  authenticated: false,
  wallet: "",
  refresh: false,
  currentComponent: <InitSignup></InitSignup>,
};

function setAuth(auth) {
  return auth;
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTH":
      return setAuth(action.payload);
    default:
      return state;
  }
};

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};
