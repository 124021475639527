import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createClient } from "wagmi";
import {
  metaMaskWallet,
  braveWallet,
  walletConnectWallet,
  coinbaseWallet,
  ledgerWallet,
  trustWallet,
  argentWallet,
  imTokenWallet,
  mewWallet,
  omniWallet,
  injectedWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { arbitrum, arbitrumGoerli, aurora, auroraTestnet, avalanche, avalancheFuji, baseGoerli, boba, bronos, bronosTestnet, bsc, bscTestnet, canto, celo, celoAlfajores, cronos, crossbell, dfk, dogechain, evmos, evmosTestnet, fantom, fantomTestnet, filecoin, filecoinCalibration, filecoinHyperspace, flare, flareTestnet, foundry, gnosis, gnosisChiado, goerli, hardhat, harmonyOne, iotex, iotexTestnet, klaytn, localhost, mainnet, metis, metisGoerli, moonbaseAlpha, moonbeam, moonriver, okc, optimism, optimismGoerli, polygon, polygonMumbai, polygonZkEvmTestnet, scrollTestnet, sepolia, shardeumSphinx, skaleBlockBrawlers, skaleCalypso, skaleCalypsoTestnet, skaleChaosTestnet, skaleCryptoBlades, skaleCryptoColosseum, skaleEuropa, skaleEuropaTestnet, skaleExorde, skaleHumanProtocol, skaleNebula, skaleNebulaTestnet, skaleRazor, skaleTitan, skaleTitanTestnet, songbird, songbirdTestnet, taraxa, taraxaTestnet, telos, telosTestnet, wanchain, wanchainTestnet, zhejiang, zkSync, zkSyncTestnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

const core = {
  id: 1116,
  name: 'Core',
  network: 'Core Chain MainNet',
  iconUrl: 'https://s1.coincarp.com/logo/1/core-dao.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Core',
    symbol: 'CORE',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc-core.icecreamswap.com'],
    },
  },
  blockExplorers: {
    default: { name: 'CoreScan', url: 'https://scan.coredao.org' }
  },
  testnet: false,
};

export const { chains, provider, webSocketProvider } = configureChains(
  [core, arbitrum, arbitrumGoerli, aurora, auroraTestnet, avalanche, avalancheFuji, baseGoerli, boba, bronos, bronosTestnet, bsc, bscTestnet, canto, celo, celoAlfajores, cronos, crossbell, dfk, dogechain, evmos, evmosTestnet, fantom, fantomTestnet, filecoin, filecoinCalibration, filecoinHyperspace, flare, flareTestnet, foundry, gnosis, gnosisChiado, goerli, hardhat, harmonyOne, iotex, iotexTestnet, klaytn, localhost, mainnet, metis, metisGoerli, moonbaseAlpha, moonbeam, moonriver, okc, optimism, optimismGoerli, polygon, polygonMumbai, polygonZkEvmTestnet, scrollTestnet, sepolia, shardeumSphinx, skaleBlockBrawlers, skaleCalypso, skaleCalypsoTestnet, skaleChaosTestnet, skaleCryptoBlades, skaleCryptoColosseum, skaleEuropa, skaleEuropaTestnet, skaleExorde, skaleHumanProtocol, skaleNebula, skaleNebulaTestnet, skaleRazor, skaleTitan, skaleTitanTestnet, songbird, songbirdTestnet, taraxa, taraxaTestnet, telos, telosTestnet, wanchain, wanchainTestnet, zhejiang, zkSync, zkSyncTestnet],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: "Wallets",
    wallets: [
      metaMaskWallet({ chains }),
      ledgerWallet({ chains }),
      braveWallet({ chains }),
      trustWallet({ chains }),
      coinbaseWallet({ appName: "dapp", chains }),
      argentWallet({ chains }),
      imTokenWallet({ chains }),
      mewWallet({ chains }),
      omniWallet({ chains }),
      walletConnectWallet({ chains }),
      injectedWallet({ chains }),
    ],
  },
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});
