import AuthGuard from "guard/auth";
import Navbar from "components/menu/Navbar";
import DashbordPage from "components/pages/DashboardPage";
import IcecreamPage from "components/pages/IcecreamPage";
import { useState } from "react";

function Dashboard() {
  const [project] = useState(
    window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false
  );

  return (
    <AuthGuard>
      <div className="relative min-h-screen flex flex-col">
        <Navbar></Navbar>
        {project.toString() === "icecreamswap" ||  project.toString() === "false" ? <IcecreamPage></IcecreamPage> : <DashbordPage></DashbordPage>}
      </div>
    </AuthGuard>
  );
}

export default Dashboard;
