import { NetworkPublic, NetworkAuth } from "services/axios";

export default {
  Init(request, config) {
    return NetworkPublic.post(
      `${process.env.REACT_APP_NETWORK_HOST}/network/init`,
      request,
      config
    );
  },
  Login(data, config) {
    return NetworkPublic.post(
      `${process.env.REACT_APP_NETWORK_HOST}/network/login`,
      data,
      config
    );
  },
  Signup(request, config, token) {
    return NetworkPublic.post(
      `${process.env.REACT_APP_NETWORK_HOST}/network/signup?token=${token}`,
      request,
      config
    );
  },
  Me(project) {
    return NetworkAuth.get(
      `${process.env.REACT_APP_NETWORK_HOST}/network/me/${project}`
    );
  },
};
