import { AuthContext } from "context/auth";
import { useContext, useState, useEffect } from "react";
import Input from "components/ui/Input";
import Textarea from "components/ui/Textarea";
import ChoiceSelector from "components/ui/ChoiceSelector";
import Account from "components/ui/Account";
import Button from "components/ui/Button";
import { useForm } from "react-hook-form";
import {
  faEnvelope,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faLandmark, faUser } from "@fortawesome/pro-solid-svg-icons";
import AuthService from "services/auth";
import AuthCode from "components/auth/AuthCode";
import Alert from "components/ui/Alert";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory, useLocation } from "react-router-dom";
import ProjectService from "services/projects";
import CountrySelector from "components/ui/CountrySelector";
import Upload from "components/ui/Upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function InitLogin() {
  const [, authDispatch] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("INDIVIDUAL");
  const [error, setError] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { register, handleSubmit, errors } = useForm();
  const [project] = useState(
    window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false
  );
  const [settings, setSettings] = useState(null);
  const [choices, setChoices] = useState([]);
  const [custom, setCustom] = useState([]);
  const [ready, setReady] = useState(false);
  const [settingReady, setSettingReady] = useState(false);
  const [captchaReady, setCaptchaReady] = useState(false);
  const [token, setToken] = useState("");
  const [dropDownReady, setDropDownReady] = useState(true);
  const [, setCountryReady] = useState(true);
  const [listReady, setListReady] = useState(true);
  const [listElem, setListElem] = useState("");
  const [userList, setUserList] = useState([]);
  const [notModfied, setNotModfied] = useState("");
  const [check, setCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [ruhfinanceCheck, setRuhfinanceCheck] = useState(false);
  const [mystandartCheckYes, setmystandartCheckYes] = useState(false);
  const [mystandartCheckNo, setmystandartCheckNo] = useState(false);
  const router = useHistory();
  const location = useLocation();

  var ethereum_address = require("ethereum-address");

  const KYBProjects = [
    "cantina-royale-corporate",
    "matrixportventurefund",
    "jumpcrypto",
    "moonpay",
    "safelaunch-kyb",
    "oursong-clients",
    "ashswap-corporate",
    "legacynetwork-corporate",
    "hatom-corporate",
    "efilplus",
    "polygon-edd-corporate",
    "entity-corporate",
  ];

  const NonRequiredField = ["polygon-edd", "polygon-edd-corporate"];

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);

    let userChoises = [];

    if (
      (project.toString() === "mohash" ||
        project.toString() === "ruhfinance") &&
      check === false
    ) {
      setError(`Please read the CTO template`);
      setLoading(false);
      return;
    }

    if (
      project.toString() === "mystandard" &&
      mystandartCheckNo === false &&
      mystandartCheckYes === false
    ) {
      setError(`Please answer the question by yes or no`);
      setLoading(false);
      return;
    }

    if (project.toString() === "mystandard") {
      userChoises.push({
        slug: "is_investor",
        value: mystandartCheckYes.toString(),
      });

      userChoises.push({
        slug: "is_no_investor",
        value: mystandartCheckNo.toString(),
      });
    }

    const recaptchaToken = await executeRecaptcha("login_page");

    const config = {
      headers: {
        Recaptcha: recaptchaToken,
      },
    };

    for (var property in data) {
      if (
        property !== "email" &&
        settings.auth.components[property].type === "DROPDOWN" &&
        data[property] !== ""
      ) {
        let newCHoices = choices;
        newCHoices[property] = data[property];
        await setChoices(newCHoices);
      }
    }

    var arrayLength = settings.auth.components.length;
    for (var i = 0; i < arrayLength; i++) {
      let element = {};

      if (settings.auth.components[i].type === "DROPDOWN") {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
        element.value = choices[i];
        userChoises.push(element);
      } else if (settings.auth.components[i].type === "COUNTRY") {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
        element.value = choices[i];
        userChoises.push(element);
      } else if (settings.auth.components[i].type === "TEXTAREA") {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
        element.value = choices[i];
        userChoises.push(element);
      } else if (
        settings.auth.components[i].type === "UPLOAD" &&
        project.toString() !== "revam"
      ) {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
        element.value = choices[i];
        userChoises.push(element);
      } else if (
        settings.auth.components[i].type === "UPLOAD" &&
        project.toString() === "revam" &&
        accountType === "CORPORATE"
      ) {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
        element.value = choices[i];
        userChoises.push(element);
      } else if (settings.auth.components[i].type === "CHECKBOX") {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
      } else if (settings.auth.components[i].type === "NOT_DISPLAY") {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
        element.value = choices[i];
        userChoises.push(element);
      } else if (settings.auth.components[i].type === "NOT_MODIFIED") {
        element.slug = settings.auth.components[i].slug;
        if (choices[i] === "" || choices[i] === undefined) {
          setError(`Missing ${settings.auth.components[i].slug}`);
          setLoading(false);
          return;
        }
        element.value = choices[i];
        userChoises.push(element);
      } else {
        for (const property in data) {
          if (property == i) {
            element.slug = settings.auth.components[i].slug;
            if (
              (data[property] === "" || data[property] === undefined) &&
              !NonRequiredField.includes(project.toString())
            ) {
              setError(`Missing ${settings.auth.components[i].slug}`);
              setLoading(false);
              return;
            }

            if (
              project.toString() === "dolz" &&
              !ethereum_address.isAddress(data[property])
            ) {
              setError(`Invalid ETH wallet address`);
              setLoading(false);
              return;
            }

            if (
              project.toString() === "o1labs" &&
              (data[property].length !== 55 ||
                !data[property].startsWith("B62"))
            ) {
              setError(`Invalid wallet address`);
              setLoading(false);
              return;
            }

            element.value = data[property];
            userChoises.push(element);
          }
        }
      }
    }

    if (data.email === undefined) {
      data.email = email;
    }

    const body = {
      email: data.email,
      type: accountType,
      project: project,
      choices: userChoises,
      list: userList,
    };

    if (body.project === false) {
      body.project = "false";
    }

    await AuthService.Signup(body, config, token)
      .then((data) => {
        if (settings.jwt === true) {
          authDispatch({
            type: "SET_AUTH",
            payload: {
              user: {
                email: data.email,
                code: "",
                type: accountType,
                project: project.toString(),
                first_auth: data.first_auth,
              },
              currentComponent: <AuthCode></AuthCode>,
            },
          });

          router.push("/?token=" + token);
          return;
        }
        authDispatch({
          type: "SET_AUTH",
          payload: {
            user: {
              email: data.email,
              code: "",
              type: accountType,
              project: project.toString(),
            },
            currentComponent: <AuthCode></AuthCode>,
          },
        });
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        setLoading(false);
        setError(err.message);
      });
    return () => {};
  };

  useEffect(async () => {
    const query = await new URLSearchParams(location.search);
    var wallet = query.get("wallet");
    var email = query.get("email");
    var type = query.get("type");
    setToken(query.get("token"));

    if (wallet !== "" && wallet !== undefined && wallet !== null) {
      console.log(wallet);
      setRuhfinanceCheck(true);
    }

    await ProjectService.Settings(project.toString())
      .then(async (data) => {
        if (KYBProjects.includes(project.toString()) === true) {
          data.corporate = false;
          setAccountType("CORPORATE");
        }

        if (
          wallet !== "" &&
          wallet !== undefined &&
          wallet !== null &&
          data.auth.components[0].slug === "wallet"
        ) {
          data.auth.components[0].type = "NOT_MODIFIED";
          let newCHoices = choices;
          newCHoices[0] = wallet;
          setNotModfied(wallet);
          await setChoices(newCHoices);
        }

        if (type !== "" && type !== undefined && type !== null) {
          if (type !== "CORPORATE" && type !== "INDIVIDUAL") {
            type = "INDIVIDUAL";
          }
          setAccountType(type);
        }

        if (email !== "" && email !== undefined && email !== null) {
          setEmail(email);
        }
        setSettings(data);
        setSettingReady(true);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        router.push("/network");
      });
  }, []);

  useEffect(async () => {
    if (executeRecaptcha) {
      setCaptchaReady(true);
    }
  }, [executeRecaptcha]);

  useEffect(async () => {
    if (ready) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [ready]);

  useEffect(async () => {
    if (captchaReady && settingReady) {
      setReady(true);
    }
  }, [captchaReady, settingReady]);

  async function updateChoice(index, choice) {
    setDropDownReady(false);
    if (choice === "Other") {
      let newCustom = custom;
      newCustom[index] = true;
      await setCustom(newCustom);
      console.log("custom");
      console.log(custom);
      setDropDownReady(true);
      return;
    }

    let newCHoices = choices;
    newCHoices[index] = choice;
    await setChoices(newCHoices);
    setDropDownReady(true);
  }

  async function updateTextarea(index, choice) {
    let newCHoices = choices;
    newCHoices[index] = choice;
    await setChoices(newCHoices);
  }

  async function updateCheckbox(index, choice) {
    let newCHoices = choices;
    newCHoices[index] = choice;
    await setChoices(newCHoices);
  }

  async function updateCountry(index, choice) {
    setCountryReady(false);
    // setDropDownReady(false)
    let newCHoices = choices;
    newCHoices[index] = choice.name;
    await setChoices(newCHoices);
    setCountryReady(true);
  }

  async function UploadFile(file, index) {
    if (file === null) {
      return;
    }
    setError(null);
    setLoading(true);

    let bodyFormData = new FormData();
    bodyFormData.append("document", file);
    await ProjectService.Upload(bodyFormData)
      .then(async (data) => {
        let newCHoices = choices;
        newCHoices[index] = data.filename;
        await setChoices(newCHoices);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  }

  async function removeList(index) {
    setListReady(false);
    await userList.splice(index, 1);
    setListReady(true);
    setListElem("");
  }

  async function addList(wallet) {
    setListReady(false);
    userList.push(wallet);
    setListReady(true);
    setListElem("");
  }

  const formSchema = {
    email: {
      required: "Email required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
  };

  return (
    <>
      {error ? <Alert type="error" title={error}></Alert> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        {ready && settings.corporate ? (
          <>
            <div className="flex justify-between mb-4">
              <div className="w-1/2 pr-2">
                <Account
                  full
                  value={accountType}
                  account="INDIVIDUAL"
                  icon={faUser}
                  label="Individual"
                  onClick={() => setAccountType("INDIVIDUAL")}
                />
              </div>
              <div className="w-1/2 pl-2">
                <Account
                  full
                  onClick={() => setAccountType("CORPORATE")}
                  icon={faLandmark}
                  account="CORPORATE"
                  value={accountType}
                  label="Corporate"
                />
              </div>
            </div>
          </>
        ) : null}

        {project.toString() === "o1labs" ? (
          <>
            <p className="mb-3 block text-sm font-medium text-primary-700">
              Email address. This must be the same address you entered into the{" "}
              <a
                className="cursor pointer font-bold underline"
                href="https://docs.google.com/forms/d/e/1FAIpQLSco4jVKjklnDDzC-811uZMLImjFLVgqwbH2KANLcfVhkfV1Ww/viewform"
                target="_blank"
                rel="noreferrer"
              >
                Application Form
              </a>
            </p>
          </>
        ) : null}

        {ready && !settings.jwt && email === "" ? (
          <>
            <div className="mb-5">
              <Input
                icon={faEnvelope}
                label="Email"
                referrer={register(formSchema.email)}
                placeholder="john.doe@synaps.io"
                name="email"
                error={errors.email}
                message={errors.email?.message}
              />
            </div>
          </>
        ) : null}

        {ready && !settings.jwt && email !== "" ? (
          <>
            <div className="mb-5">
              <Input
                icon={faEnvelope}
                label="Email"
                referrer={register(formSchema.email)}
                placeholder="john.doe@synaps.io"
                name="email"
                value={email}
                disabled={true}
                error={errors.email}
                message={errors.email?.message}
              />
            </div>
          </>
        ) : null}

        {project === "jigen" ? (
          <div className="cursor-pointer mb-2">
            <a
              rel="noreferrer"
              className="font-bold"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeAZUpLKttup1EWuzETRp8_50QNof4iOj91ob1Tv7BbbGkBwA/viewform?usp=sf_link"
              target="_blank"
            >
              Form to complete
            </a>
          </div>
        ) : null}

        {ready ? (
          <>
            {settings.auth.components.map((component, index) => {
              return (
                <div key={index}>
                  {project.toString() === "ruhfinance" ? (
                    <>
                      <div className="flex mb-4">
                        <input
                          className="checkbox mr-3 ml-2"
                          name="isGoing"
                          type="checkbox"
                          checked={ruhfinanceCheck}
                          onChange={() => setRuhfinanceCheck(!ruhfinanceCheck)}
                        />
                        <p className="block text-sm font-medium text-primary-700">
                          Interested in investing in stablecoins ?
                        </p>
                      </div>
                    </>
                  ) : null}

                  {component.type === "INPUT" &&
                  project.toString() === "o1labs" ? (
                    <>
                      <p className="mb-3 block text-sm font-medium text-primary-700">
                        Please share the wallet address associated with the node
                        that is sending data to the O(1) Labs Delegation Program
                        through the uptime tracking system(s). This must be the
                        same address you entered into the{" "}
                        <a
                          className="cursor pointer font-bold underline"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSco4jVKjklnDDzC-811uZMLImjFLVgqwbH2KANLcfVhkfV1Ww/viewform"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Application Form
                        </a>
                      </p>
                      <Input
                        referrer={register(index)}
                        placeholder={component.placeholder}
                        name={index}
                      />
                    </>
                  ) : null}

                  {component.type === "INPUT" &&
                  project.toString() !== "ruhfinance" &&
                  project.toString() !== "o1labs" ? (
                    <div className="mb-5">
                      <Input
                        referrer={register(index)}
                        label={component.instruction}
                        placeholder={component.placeholder}
                        name={index}
                      />
                    </div>
                  ) : null}

                  {component.type === "INPUT" &&
                  project.toString() === "ruhfinance" &&
                  ruhfinanceCheck ? (
                    <div className="mb-5">
                      <Input
                        referrer={register(index)}
                        label={component.instruction}
                        placeholder={component.placeholder}
                        name={index}
                      />
                    </div>
                  ) : null}

                  {component.type === "TEXTAREA" ? (
                    <div className="mb-5">
                      <label className="block text-sm mb-3 font-medium text-primary-700">
                        {component.instruction}
                      </label>
                      <Textarea
                        setValue={(selection) =>
                          updateTextarea(index, selection)
                        }
                        label={component.instruction}
                        placeholder={component.placeholder}
                        name={index}
                      />
                    </div>
                  ) : null}

                  {component.type === "CHECKBOX" &&
                  project.toString() !== "mystandard" ? (
                    <div className="mb-5">
                      <div className="flex">
                        <input
                          className="checkbox mr-3 ml-2 mt-1"
                          name="isGoing"
                          type="checkbox"
                          checked={choices[index]}
                          onChange={() =>
                            updateCheckbox(index, !choices[index])
                          }
                        />
                        <p className="block text-sm mb-3 font-medium text-primary-700">
                          {component.instruction}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {component.type === "UPLOAD" &&
                  project.toString() !== "revam" &&
                  project.toString() !== "o1labs" ? (
                    <div className="mb-5">
                      <label className="block text-sm mb-3 font-medium text-primary-700">
                        {component.instruction}
                      </label>
                      <Upload
                        label={`	<div class="flex">
														<div>
															<img class="mx-auto w-14 mt-1.5 mr-3" src="/img/icons/ui/icon-front-side.svg"/>
														</div>
														<div>
															<p class="text-left no-underline font-medium upload-label rounded-md filepond--label-action">Upload</p>
															<p class="fs-12 text-left">or drag and drop</p>
														</div>
													</div>`}
                        loading={false}
                        allowImagePreview={false}
                        imagePreviewHeight={225}
                        acceptedFileTypes={[
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "application/pdf",
                        ]}
                        setFile={(file) => UploadFile(file, index)}
                      ></Upload>
                    </div>
                  ) : null}

                  {component.type === "UPLOAD" &&
                  project.toString() === "o1labs" ? (
                    <div className="mb-5">
                      <label className="mt-3 block text-sm mb-3 font-medium text-primary-700">
                        Please complete, sign, and upload one of the following
                        forms{" "}
                        <a
                          className="cursor pointer font-bold underline"
                          href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          W-8 BEN
                        </a>
                        ,{" "}
                        <a
                          className="cursor pointer font-bold underline"
                          href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          W-8BENE
                        </a>
                        , or{" "}
                        <a
                          className="cursor pointer font-bold underline"
                          href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          W-9
                        </a>{" "}
                        (whichever is relevant to your situation). You must
                        upload one of these completed forms in order to be
                        eligible to receive a delegation
                      </label>
                      <Upload
                        label={`	<div class="flex">
														<div>
															<img class="mx-auto w-14 mt-1.5 mr-3" src="/img/icons/ui/icon-front-side.svg"/>
														</div>
														<div>
															<p class="text-left no-underline font-medium upload-label rounded-md filepond--label-action">Upload</p>
															<p class="fs-12 text-left">or drag and drop</p>
														</div>
													</div>`}
                        loading={false}
                        allowImagePreview={false}
                        imagePreviewHeight={225}
                        acceptedFileTypes={[
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "application/pdf",
                        ]}
                        setFile={(file) => UploadFile(file, index)}
                      ></Upload>
                    </div>
                  ) : null}

                  {component.type === "UPLOAD" &&
                  project.toString() === "revam" &&
                  accountType === "CORPORATE" ? (
                    <div className="mb-5">
                      <label className="block text-sm mb-3 font-medium text-primary-700">
                        {component.instruction}
                      </label>
                      <Upload
                        label={`	<div class="flex">
														<div>
															<img class="mx-auto w-14 mt-1.5 mr-3" src="/img/icons/ui/icon-front-side.svg"/>
														</div>
														<div>
															<p class="text-left no-underline font-medium upload-label rounded-md filepond--label-action">Upload</p>
															<p class="fs-12 text-left">or drag and drop</p>
														</div>
													</div>`}
                        loading={false}
                        allowImagePreview={false}
                        imagePreviewHeight={225}
                        acceptedFileTypes={[
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "application/pdf",
                        ]}
                        setFile={(file) => UploadFile(file, index)}
                      ></Upload>
                    </div>
                  ) : null}

                  {component.type === "LIST" ? (
                    <div className="mb-5">
                      <label className="block text-sm mb-3 font-medium text-primary-700">
                        {component.instruction}
                      </label>

                      {listReady && userList.length > 0 ? (
                        <div>
                          {userList.map((wallet, index) => {
                            return (
                              <div key={index} className="flex mb-3">
                                <div className="p-3 w-full border border-gray-200 shadow-sm items-center rounded-xl input-network">
                                  {wallet}
                                </div>
                                <h2 className="text-xl mt-3 ml-2">
                                  <span>
                                    <FontAwesomeIcon
                                      onClick={() => removeList(index)}
                                      className="cursor-pointer text-red-500 hover:text-red-700 transition duration-100 ease-in"
                                      icon={faMinusCircle}
                                    ></FontAwesomeIcon>
                                  </span>
                                </h2>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                      <div className="mt-4">
                        <div key={index} className="flex mb-3">
                          <div className="w-full">
                            <Input
                              setValue={(value) => setListElem(value)}
                              placeholder={component.placeholder}
                              name={index}
                              value={listElem}
                            />
                          </div>
                          <h2 className="text-xl mt-3 ml-2">
                            <span>
                              <FontAwesomeIcon
                                onClick={() => addList(listElem)}
                                className="cursor-pointer text-green-500 transition duration-100 ease-in"
                                icon={faPlusCircle}
                              ></FontAwesomeIcon>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {component.type === "DROPDOWN" && dropDownReady ? (
                    <div className="mb-5">
                      {custom[index] === true ? (
                        <>
                          <Input
                            referrer={register(index)}
                            label={component.instruction}
                            placeholder={component.placeholder}
                            name={index}
                          />
                        </>
                      ) : (
                        <>
                          <label className="block text-sm mb-3 font-medium text-primary-700">
                            {component.instruction}
                          </label>
                          <ChoiceSelector
                            onSelect={(selection) =>
                              updateChoice(index, selection)
                            }
                            selected={choices[index]}
                            choices={component.choices}
                          ></ChoiceSelector>
                        </>
                      )}
                    </div>
                  ) : null}

                  {component.type === "NOT_MODIFIED" ? (
                    <div className="mb-5">
                      <Input
                        disabled={true}
                        value={notModfied}
                        label={"Wallet Address"}
                        placeholder={component.placeholder}
                        name={index}
                      />
                    </div>
                  ) : null}

                  {component.type === "COUNTRY" ? (
                    <div className="mb-5">
                      <label className="block text-sm mb-3 font-medium text-primary-700">
                        {component.instruction}
                      </label>
                      <CountrySelector
                        placeholder={component.placeholder}
                        value={choices[index]}
                        setValue={(value) => updateCountry(index, value)}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </>
        ) : null}

       
        <div>
          {ready && !settings.jwt ? (
            <>
              {project.toString() === "o1labs" ? (
                <Button
                  type="submit"
                  full
                  loading={loading}
                  label="Begin signup"
                />
              ) : (
                <Button type="submit" full loading={loading} label="Verify email" />
              )}
            </>
          ) : (
            <>
              <Button
                type="submit"
                full
                loading={loading}
                label="Continue"
              ></Button>
            </>
          )}
        </div>
      </form>
    </>
  );
}
