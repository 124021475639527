import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPowerOff } from "@fortawesome/pro-duotone-svg-icons";
import { useContext, useState } from "react";
import { AuthContext } from "context/auth";
import Loader from "../ui/Loader";
import Cookie from "js-cookie";

export default function MiniProfile() {
  const [auth] = useContext(AuthContext);
  const [logoutLoading] = useState(false);

  async function logout() {
    Cookie.remove("s_auth");
    window.location.href = "/signup";
  }

  return (
    <div className="flex justify-between bg-white z-10 p-2 border-profile mt-5">
      <div></div>
      <div className="flex">
        <div className="flex">
          <div className="px-4 py-3 profil-signed">
            <p className="text-sm leading-5">Signed in as</p>
            <p className="text-sm font-medium leading-5 text-gray-900 truncate">
              {auth.user.email}
            </p>
          </div>
          <div className="pb-2 mt-2 px-2 outline-none">
            <a
              onClick={() => logout()}
              className={
                "rounded-xl outline-none hover:bg-red-100 transition duration-200 ease-in  text-red-600 cursor-pointer flex w-full p-3 text-sm leading-5"
              }
            >
              {logoutLoading ? (
                <Loader className="mr-3" size="medium" color="red-600"></Loader>
              ) : (
                <FontAwesomeIcon
                  className="mr-3 h-5 w-5 text-red-600"
                  icon={faPowerOff}
                ></FontAwesomeIcon>
              )}
              Sign out
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
