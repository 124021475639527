import { NetworkAuth } from "services/axios";

export default {
  CheckDuplicate(project, token) {
    return NetworkAuth.get(
      `${process.env.REACT_APP_NETWORK_HOST}/network/check/${project}?token=${token}`
    );
  },
  ProcessDuplicate(project, data, token) {
    return NetworkAuth.post(
      `${process.env.REACT_APP_NETWORK_HOST}/network/duplicate/${project}?token=${token}`,
      data
    );
  },
  UpdateTransaction(project, body) {
    return NetworkAuth.put(
      `${process.env.REACT_APP_NETWORK_HOST}/network/transaction/${project}`,
      body
    );
  },
  IcecreamSetup(project) {
    return NetworkAuth.get(
      `${process.env.REACT_APP_NETWORK_HOST}/network/icescream/setup/${project}`,);
  },
  IcecreamVerify(project, body) {
    return NetworkAuth.post(
      `${process.env.REACT_APP_NETWORK_HOST}/network/icescream/status/${project}`, body);
  },
};