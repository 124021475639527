import { NetworkPublic } from "services/axios";

export default {
  Settings(project) {
    return NetworkPublic.get(
      `${process.env.REACT_APP_NETWORK_HOST}/network/settings/${project}`
    );
  },
  Upload(body) {
    return NetworkPublic.post(
      `${process.env.REACT_APP_NETWORK_HOST}/network/upload`,
      body
    );
  },
};
