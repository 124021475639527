import {
  createAuthenticationAdapter,
  RainbowKitAuthenticationProvider,
} from "@rainbow-me/rainbowkit";
import { useMemo, useState, useContext } from "react";
import SessionService from "services/session";
import Cookie from "js-cookie";
import { AuthContext } from "context/auth";
import * as Notification from "components/ui/Notification";

function AuthenticationProvider({ children }) {
  const [status, setStatus] = useState("unauthenticated");
  const [authState, authDispatch] = useContext(AuthContext);

  const [project] = useState(
    window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false
  );

  const authenticationAdapter = useMemo(() => {
    return createAuthenticationAdapter({
      getNonce: async () => {
        let nonce = await SessionService.IcecreamSetup(project)
        .then(async (data) => {
          return data.nonce
        })
        .catch((err) => {
          console.log(err)
        });

        console.log(nonce)
        return nonce
      },

      createMessage: ({ nonce }) => {
        return `Synaps wants you to sign in with your Ethereum account\nWelcome to IcecreamSwap subdomain!\nThis request will not trigger a blockchain transaction or cost any gas fees.\nNonce: ${nonce}`;
      },

      getMessageBody: ({ message }) => message.toString(),

      verify: async ({ message, signature }) => {
        const s_wallet = Cookie.get("s_wallet");

        let body = {
          "signature": signature,
          "challenge_encoded": message,
          "public_address": s_wallet,
        }
        await SessionService.IcecreamVerify(project, body)
        .then(async (data) => {
          setStatus("authenticated");
          console.log("IcecreamVerify data")
          console.log(data)
          const auth = {
            user: {
              email: data.email,
              code: "",
              type: data.account_type,
              project: data.project,
              session_id: data.session_id,
              access: data.access_token,
            },
            refresh: true,
            settings: data.settings,
            authenticated: true,
          };
          
          Cookie.set("s_auth", data.access_token);  
          authDispatch({
            type: "SET_AUTH",
            payload: auth,
          });
        })
        .catch((err) => {
          Notification.error(err.message);
        });
        
        return true;
      },
    });
  }, []);

  return (
    <RainbowKitAuthenticationProvider
      adapter={authenticationAdapter}
      status={status}
    >
      {children}
    </RainbowKitAuthenticationProvider>
  );
}

export default AuthenticationProvider;
