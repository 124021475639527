import { AuthContext } from "context/auth";
import { useContext, useState, useEffect } from "react";
import Input from "components/ui/Input";
import Account from "components/ui/Account";
import Button from "components/ui/Button";
import { useForm } from "react-hook-form";
import { faEnvelope } from "@fortawesome/pro-duotone-svg-icons";
import { faLandmark, faUser } from "@fortawesome/pro-solid-svg-icons";
import AuthService from "services/auth";
import AuthCode from "components/auth/AuthCode";
import Alert from "components/ui/Alert";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory } from "react-router-dom";
import ProjectService from "services/projects";

export default function InitLogin() {
  const [, authDispatch] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("INDIVIDUAL");
  const [error, setError] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { register, handleSubmit, errors } = useForm();
  const [project] = useState(
    window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false
  );
  const router = useHistory();
  const [ready, setReady] = useState(false);
  const [settings, setSettings] = useState(null);
  const [settingReady, setSettingReady] = useState(false);
  const [captchaReady, setCaptchaReady] = useState(false);

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);

    const recaptchaToken = await executeRecaptcha("login_page");

    const config = {
      headers: {
        Recaptcha: recaptchaToken,
      },
    };

    const body = {
      email: data.email,
      type: accountType,
      project: project.toString(),
    };

    await AuthService.Init(body, config)
      .then(() => {
        authDispatch({
          type: "SET_AUTH",
          payload: {
            user: {
              email: body.email,
              code: "",
              type: accountType,
              project: project.toString(),
            },
            currentComponent: <AuthCode></AuthCode>,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
    return () => {};
  };

  useEffect(async () => {
    await ProjectService.Settings(project.toString())
      .then((data) => {
        if (KYBProjects.includes(project.toString()) === true) {
          data.corporate = false;
          setAccountType("CORPORATE");
        }
        setSettings(data);
        setSettingReady(true);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        router.push("/network");
      });
  }, []);

  const KYBProjects = [
    "cantina-royale-corporate",
    "matrixportventurefund",
    "jumpcrypto",
    "moonpay",
    "safelaunch-kyb",
    "oursong-clients",
    "ashswap-corporate",
    "legacynetwork-corporate",
    "hatom-corporate",
    "efilplus",
    "polygon-edd-corporate",
    "entity-corporate",
  ];

  useEffect(async () => {
    if (executeRecaptcha) {
      setCaptchaReady(true);
    }
  }, [executeRecaptcha]);

  useEffect(async () => {
    if (captchaReady && settingReady) {
      setReady(true);
    }
  }, [captchaReady, settingReady]);

  const formSchema = {
    email: {
      required: "Email required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email",
      },
    },
  };

  useEffect(async () => {
    if (ready) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [ready]);

  return (
    <>
      {error ? <Alert type="error" title={error}></Alert> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        {ready && settings.corporate ? (
          <>
            <div className="flex justify-between mb-4">
              <div className="w-1/2 pr-2">
                <Account
                  full
                  value={accountType}
                  account="INDIVIDUAL"
                  icon={faUser}
                  label="Individual"
                  onClick={() => setAccountType("INDIVIDUAL")}
                />
              </div>
              <div className="w-1/2 pl-2">
                <Account
                  full
                  onClick={() => setAccountType("CORPORATE")}
                  icon={faLandmark}
                  account="CORPORATE"
                  value={accountType}
                  label="Corporate"
                />
              </div>
            </div>
          </>
        ) : null}
        {ready ? (
          <div className="mb-5">
            <Input
              icon={faEnvelope}
              label="Email"
              referrer={register(formSchema.email)}
              placeholder="john.doe@synaps.io"
              name="email"
              error={errors.email}
              message={errors.email?.message}
            />
          </div>
        ) : null}
        <div>
          <Button type="submit" full loading={loading} label="Sign In" />
        </div>
      </form>
    </>
  );
}
