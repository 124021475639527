import { useEffect, useState, useContext } from "react";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/ui/Button";

import SubNav from "../menu/SubNav";
import Footer from "../menu/Footer";
import Hooks from "components/tool/Hooks";
import SynapsClient from "lib/synaps";
import { faPassport } from "@fortawesome/pro-duotone-svg-icons";
import { AuthContext } from "context/auth";
import { useLocation } from "react-router-dom";
import SessionService from "services/session";
import DuplicateOptions from "components/user/DuplicateData";
import Cookie from "js-cookie";
import AuthService from "services/auth";
import { useHistory } from "react-router-dom";
import Input from "components/ui/Input";

export default function DashboardPage() {
  const width = Hooks();
  const [authState, authDispatch] = useContext(AuthContext);
  const [project] = useState(
    window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false
  );
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [duplicateOptions, setDuplicateOptions] = useState(null);
  const [duplicateInited, setDuplicateInited] = useState(false);
  const location = useLocation();
  const [isInit, setIsInit] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [coinbaseID, setCoinbaseID] = useState("");
  const [radomURL, setRadomURL] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [wallet, setWallet] = useState("");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [sessionStatus, setSessionStatus] = useState("PENDING");
  const router = useHistory();

  function InitSynaps(session, color, jwt) {
    setIsInit(false);
    let colorSettings = {
      primary: color,
      secondary: "FFFFFF",
    };

    if (jwt === false) {
      const Synaps = new SynapsClient(
        session,
        authState.user.type.toLowerCase()
      );
      Synaps.init({
        type: "embed",
        colors: colorSettings,
      });
    }

    const SynapsModal = new SynapsClient(
      session,
      authState.user.type.toLowerCase()
    );
    SynapsModal.init({
      type: "modal",
      colors: colorSettings,
    });

    SynapsModal.on("finish", () => {
      console.log("FINISH EVENT");
      var payload = {
        type: "finish",
        data: {},
      };
      window.parent.postMessage(payload, "*");
    });

    setIsInit(true);
  }

  const NonCommonFlow = ["artcandie", "ubisoft", "efilplus", "false"];

  async function CheckDuplicate() {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    await SessionService.CheckDuplicate(project.toString(), token)
      .then(async (data) => {
        if (data.user_data.length > 0) {
          setOpen(true);
          setDuplicateOptions(data.user_data);
        } else {
          if (authState.settings.color !== "") {
            InitSynaps(
              authState.user.session_id,
              authState.settings.color,
              authState.settings.jwt
            );
          } else {
            InitSynaps(authState.user.session_id, "000000", data.jwt);
          }
        }
        setDuplicateInited(true);
      })
      .catch(() => {
        if (authState.settings.color !== "") {
          InitSynaps(
            authState.user.session_id,
            authState.settings.color,
            authState.settings.jwt
          );
        } else {
          InitSynaps(
            authState.user.session_id,
            "000000",
            authState.settings.jwt
          );
        }
      });
    return () => {};
  }

  async function ProcessDuplicate(data) {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    setSelected(data.session_id);

    let body = {
      session_id: data.session_id,
    };

    setDuplicateInited(false);

    await SessionService.ProcessDuplicate(project.toString(), body, token)
      .then(async () => {
        await AuthService.Me(project.toString())
          .then((data) => {
            const auth = {
              user: {
                email: data.email,
                code: "",
                type: data.account_type,
                project: data.project,
                session_id: data.session_id,
                access: data.access_token,
              },
              settings: data.settings,
              authenticated: true,
            };

            authDispatch({
              type: "SET_AUTH",
              payload: auth,
            });

            Cookie.set("s_auth", data.access_token);

            if (authState.settings.color !== "") {
              InitSynaps(
                data.session_id,
                authState.settings.color,
                authState.settings.jwt
              );
            } else {
              InitSynaps(data.session_id, "000000", authState.settings.jwt);
            }
            setOpen(false);
          })
          .catch(() => {
            if (authState.settings.color !== "") {
              InitSynaps(
                authState.user.session_id,
                authState.settings.color,
                authState.settings.jwt
              );
            } else {
              InitSynaps(
                authState.user.session_id,
                "000000",
                authState.settings.jwt
              );
            }
            setOpen(false);
          });
        return () => {};
      })
      .catch(() => {
        if (authState.settings.color !== "") {
          InitSynaps(
            authState.user.session_id,
            authState.settings.color,
            authState.settings.jwt
          );
        } else {
          InitSynaps(
            authState.user.session_id,
            "000000",
            authState.settings.jwt
          );
        }
        setOpen(false);
      });
    return () => {};
  }

  async function UpdateTransaction() {
    if (transactionHash === "" || wallet === "") {
      return;
    }

    let body = {
      transaction_hash: transactionHash,
      wallet: wallet,
    };

    setLoading(true);

    await SessionService.UpdateTransaction(project.toString(), body)
      .then(async (data) => {
        setLoading(false);
        setUpdated(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    return () => {};
  }

  useEffect(async () => {
    await AuthService.Me(project.toString())
      .then(async (data) => {
        console.log("data");
        console.log(data);
        const auth = {
          user: {
            email: data.email,
            code: "",
            type: data.account_type,
            project: data.project,
            session_id: data.session_id,
            access: data.access_token,
          },
          settings: data.settings,
          authenticated: true,
        };

        await setCoinbaseID(data.coinbase_id);
        await setRadomURL(data.radom_url);
        await setSessionStatus(data.session_status);

        authDispatch({
          type: "SET_AUTH",
          payload: auth,
        });

        Cookie.set("s_auth", data.access_token);

        await CheckDuplicate();
      })
      .catch(() => {
        if (authState.settings.color !== "") {
          InitSynaps(
            authState.user.session_id,
            authState.settings.color,
            authState.settings.jwt
          );
        } else {
          InitSynaps(
            authState.user.session_id,
            "000000",
            authState.settings.jwt
          );
        }
        setOpen(false);
      });
    return () => {};
  }, []);

  useEffect(async () => {
    if (open === false && duplicateInited) {
      if (authState.settings.color !== "") {
        InitSynaps(
          authState.user.session_id,
          authState.settings.color,
          authState.settings.jwt
        );
      } else {
        InitSynaps(authState.user.session_id, "000000", authState.settings.jwt);
      }
    }
  }, [open]);

  useEffect(async () => {
    if (
      width < 768 &&
      isInit === true &&
      authState.user.first_auth === "NOT_ACTIVATED" &&
      open === false &&
      clicked === false
    ) {
      setClicked(true);
      let button = document.getElementById("synaps-btn");
      button.click();
    }
  }, [width, isInit]);

  function GoToStaking() {
    window.open("https://account.aleph.im", "_blank");
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container">
            <div className="md:flex mx-auto md:justify-between mt-3">
              {/* {project.toString() !== 'false' && project.toString() !== 'efilplus' ? */}
              <div
                className={`${
                  width >= 768 ? "block" : "hidden"
                } mt-5 mr-5 ml-5 modal-place md:w-1/2`}
              >
                <div
                  className="synaps-modal modal-background rounded-xl bg-white p-4"
                  id="synaps-embed"
                ></div>
              </div>
              {/* : null} */}

              <div
                className={`${
                  width >= 768 ? "hidden" : "block"
                } mt-5 text-center mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-1/2`}
              >
                <FontAwesomeIcon
                  className={
                    "text-gray-500 text-3xl mx-auto mt-3 text-3xl mt-0.5"
                  }
                  icon={faPassport}
                ></FontAwesomeIcon>
                <p className="mt-1">
                  Click on the following button to start verification process
                </p>
                <button
                  type="submit"
                  id="synaps-btn"
                  className="mx-auto my-2 mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus-primary-btn active:bg-primary-700 transition duration-150 ease-in-out"
                >
                  Verify my identity
                </button>
              </div>
              {authState.settings !== undefined &&
              authState.settings !== null &&
              !authState.settings.jwt &&
              !authState.settings.did &&
              !NonCommonFlow.includes(project.toString()) ? (
                <div className="mt-5 md:w-1/2 mx-auto">
                  <div className="bg-white rounded-xl p-6 shadow verification-steps text-network">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">
                      Start your verification process
                    </h3>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>Complete each verification step</div>
                    </div>

                    <div className="flex mt-5">
                      <div className="mt-3">
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        <p>Your document will be verified by our agents.</p>
                        <p>
                          (It can take up to 24 hours during peak traffic
                          times.)
                        </p>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        You will receive an e-mail notification if any
                        document(s) are{" "}
                        <span className="text-red-500">Rejected</span>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Once every step has been{" "}
                        <span className="text-green-500">Verified</span>, you
                        receive a validation e-mail.
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {authState.settings !== undefined &&
              authState.settings !== null &&
              authState.settings.did &&
              !authState.settings.jwt &&
              !NonCommonFlow.includes(project.toString()) ? (
                <div className="md:w-1/2 mx-auto">
                  <div className="bg-white rounded-xl p-6 shadow verification-steps text-network">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">
                      Start your verification process
                    </h3>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>Complete each verification step</div>
                    </div>

                    <div className="flex mt-5">
                      <div className="mt-3">
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        <p>Your document will be verified by our agents.</p>
                        <p>
                          (It can take up to 24 hours during peak traffic
                          times.)
                        </p>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        You will receive an e-mail notification if any
                        document(s) are{" "}
                        <span className="text-red-500">Rejected</span>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Once every step has been{" "}
                        <span className="text-green-500">Verified</span>, you
                        receive a validation e-mail.
                      </div>
                    </div>
                  </div>

                  <div className="rounded-xl p-6 shadow mt-6 border-anima text-network">
                    <p className="text-xl text-primary-800 font-bold">
                      You&apos;ve dreamed of getting rid of KYC for years ?
                    </p>
                    <div className="flex">
                      <p className="text-xl text-primary-800 mb-1 font-bold">
                        Anima is your savior and will reward you in addition{" "}
                      </p>
                      <img
                        className="h-6 ml-2 cursor-pointer transition duration-200 ease-in"
                        src="https://storage.googleapis.com/synaps-static-assets/anima_logo.png"
                      ></img>
                    </div>
                    <div className="flex mt-5">
                      <div>
                        Anima allows you to decentralize your identity for
                        reuse. It is secured by encryption with your favorite
                        crypto wallet.
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        Just so you know, Anima owners will be rewarded at token
                        launch for
                        <li>Creating their Anima after verification process</li>
                        <li>Using their Anima to skip KYC process</li>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {authState.settings !== undefined &&
              authState.settings !== null &&
              project.toString() === "ubisoft" ? (
                <div className="md:w-1/2 mx-auto">
                  <div className="bg-white rounded-xl p-6 shadow verification-steps text-network">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">
                      Ubisoft would like to verify your identity through its
                      partner Synaps&apos; KYC solution
                    </h3>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Start your verification now and confirm your delegation
                        on the Ubisoft node !
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div className="mt-3">
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        <p>
                          Your document will be verified by Synaps&apos; agents.
                        </p>
                        <p>
                          (It can take up to 24 hours during peak traffic
                          times.)
                        </p>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Once every step has been{" "}
                        <span className="text-green-500">Verified</span>, you
                        receive a validation e-mail.
                      </div>
                    </div>

                    {sessionStatus === "FINISHED" ? (
                      <div className="flex mt-5">
                        <div>
                          <p>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-green-500 mr-3 mt-4"
                            />
                          </p>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            full
                            onClick={() => GoToStaking()}
                            loading={loading}
                            label="Start staking now"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="rounded-xl p-6 shadow mt-6 border-anima text-network">
                    <p className="text-xl text-primary-800 font-bold">
                      You&apos;ve dreamed of getting rid of KYC for years ?
                    </p>
                    <div className="flex">
                      <p className="text-xl text-primary-800 mb-1 font-bold">
                        Anima is your savior and will reward you in addition{" "}
                      </p>
                      <img
                        className="h-6 ml-2 cursor-pointer transition duration-200 ease-in"
                        src="https://storage.googleapis.com/synaps-static-assets/anima_logo.png"
                      ></img>
                    </div>
                    <div className="flex mt-5">
                      <div>
                        Anima allows you to decentralize your identity for
                        reuse. It is secured by encryption with your favorite
                        crypto wallet.
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        Just so you know, Anima owners will be rewarded at token
                        launch for
                        <li>Creating their Anima after verification process</li>
                        <li>Using their Anima to skip KYC process</li>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {authState.settings !== undefined &&
              authState.settings !== null &&
              !authState.settings.jwt &&
              !authState.settings.did &&
              project.toString() === "artcandie" ? (
                <div className="mt-5 md:w-1/2 mr-5 ml-5 mx-auto">
                  <div className="bg-white rounded-xl p-6 shadow text-network">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">
                      Commencez votre processus de vérification
                    </h3>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Complétez chaque étape de vérification jusqu à ce que
                        chaque statut indique{" "}
                        <span className="text-green-500">Vérifié</span> ou{" "}
                        <span className="text-orange-500">En attente</span>
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Attendez que Synaps vérifie vos données. Le temps
                        nécessaire à la vérification varie en fonction de la
                        taille du projet et des exigences de vérification.
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Vous recevrez une notification par e-mail si un ou
                        plusieurs documents sont{" "}
                        <span className="text-red-500">rejetés</span>.
                      </div>
                    </div>

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Une fois que chaque étape a été{" "}
                        <span className="text-green-500">vérifiée</span>, vous
                        recevez un e-mail vous informant que nous avons accepté
                        votre candidature et que vous pouvez accéder à Art Can
                        Die Dashboard pour acheter des jetons.
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {(authState.settings !== undefined &&
                authState.settings !== null &&
                project.toString() === "efilplus") ? (
                <div className="mt-5 w-1/2 mx-auto">
                  <div className="bg-white rounded-xl p-6 shadow verification-steps text-network">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">
                      Start your verification process
                    </h3>
                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Process to <strong>payment</strong> to access to the
                        verification process
                      </div>
                    </div>

                    {radomURL !== undefined ? (
                      <div className="flex mt-5 justify-center">
                        <div></div>
                        <div className="cursor-pointer hover:border-primary-700 focus:ring-primary-500 hover:bg-primary-600 flex transition ease-in duration-150 justify-center border  bg-primary-500 rounded-xl shadow-sm text-sm font-medium focus:outline-none focus:ring-2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={radomURL}
                            className="block px-4 py-3 text-white font-bold "
                          >
                            Process payment
                          </a>
                        </div>
                        <div></div>
                      </div>
                    ) : null}

                    <div className="flex mt-5">
                      <div>
                        <p>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-500 mr-3 mt-0.5"
                          />
                        </p>
                      </div>
                      <div>
                        Once the verification has been{" "}
                        <span className="text-green-500">Approved</span>, you
                        receive a validation e-mail.
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
      <DuplicateOptions
        open={open}
        duplicates={duplicateOptions}
        onSelect={(data) => ProcessDuplicate(data)}
        selected={selected}
        project={project.toString()}
        onClose={() => setOpen(false)}
      ></DuplicateOptions>
    </div>
  );
}
