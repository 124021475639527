import AuthGuard from "guard/auth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProjectService from "services/projects";
import Navbar from "components/menu/Navbar";
import Wallet from "components/wallet";

export default function WalletPage(props) {
  const [project] = useState(
    window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false
  );
  const router = useHistory();
  const [ready, setReady] = useState(false);
  const [settings, setSettings] = useState(null);

  useEffect(async () => {
    await ProjectService.Settings(project)
      .then((data) => {
        setSettings(data);
        setReady(true);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        router.push("/network");
      });
  }, []);

  return (
    <AuthGuard>
      <div className="relative min-h-screen flex flex-col">
        <Navbar></Navbar>
        <Wallet />
      </div>
    </AuthGuard>
  );
}
