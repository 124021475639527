import MiniButton from "components/ui/MiniButton";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";

export default function NotFound() {
  function goHome() {
    window.location.href = "/";
  }

  return (
    <div className="min-h-screen px-8 bg-primary-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="flex justify-center items-center">
        <div>
          <img className="w-28" src="/img/logo/synaps-logo-s.svg"></img>
        </div>
        <div className="ml-6">
          <h2 className="font-bold text-7xl text-white">404</h2>
          <h2 className="text-white font-light mt-3 text-4xl uppercase">
            Page not found
          </h2>
          <div className="mt-4">
            <MiniButton
              icon={faAngleRight}
              label="Go home"
              currentBg="primary"
              theme="secondary"
              onClick={() => goHome()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
